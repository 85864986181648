import {Link} from "react-router-dom";
import React from "react";

export interface ExtendingButtonProps extends React.HTMLProps<HTMLAnchorElement> {
    to: string;
    width?: "90" | "125";
    state?: any;
    icon?: string;
    label?: string;
    type?: "primary" | "secondary" | "dark" | "light" | "outline-primary" | "outline-secondary" | "outline-dark" | "outline-light" | undefined;
}

function ExtendingButton(props: ExtendingButtonProps) {
    const type = props.type ? "btn-" + props.type : "";
    const classNames = `icn-btn  ${type} ${props.icon ? "btn-icn" : ""}`;
    //className="icn-btn btn-dwnl-paginationreport btn-outline-primary">
    const extendedClass = `extending-button-wrapper w-${props.width ?? "90" }`
    
    return <div className={extendedClass}>
        <div className={classNames}>
            <Link className={`inner ${type}`} {...props}>
                <img src={props.icon} alt=""/>
                <span> {props.label} </span>
            </Link>
        </div>
    </div>
}

export default ExtendingButton;
//
// <a className="icn-btn btn-outline-primary btn-icn" href="/">
//     <span>
//         <img
//     src="/static/media/arrow-down-to-line.57c9b39fc1daedc208bf96d38f1d9557.svg" alt=""/></span>Download report</a>
// <a className="icn-btn btn-outline-primary btn-icn" href="/">
//     <img src="/static/media/arrow-down-to-line.57c9b39fc1daedc208bf96d38f1d9557.svg" alt=""/>
//     <span> Download report </span>
// </a>
//
// ua href="" className="icn-btn btn-dwnl-report btn-outline-primary">
//     <img src="assets/imgs/icons/arrow-down-to-line.svg" alt=""/>
//     <span>Download report</span>
// </a>