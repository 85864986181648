import React from "react";

export interface TabButtonProps extends React.ComponentProps<any> {
    label: string;
    active?: boolean;
    onClick?: () => void;
}

function TabButton(props: TabButtonProps) {
    return <button className={`tab-btn link ${props.active ? "active" : ""}`} onClick={props.onClick}>
        {props.label}
    </button>
}

export default TabButton;