import {Link} from "react-router-dom";
import React, {MouseEventHandler} from "react";

export interface LinkButtonProps {
    icon?: string;
    label?: string;
    type?: "primary" | "secondary" | "dark" | "light" | "outline-primary" | "outline-secondary" | "outline-dark" | "outline-light" | undefined;
    onClick?: MouseEventHandler<HTMLAnchorElement>
}

function IconButton(props: LinkButtonProps) {
    const classnames = `btn ${props.type ? "btn-" + props.type : ""} ${props.icon ? "btn-icn" : ""}`;
    
    return <a className={classnames} onClick={props.onClick}> 
        <span>
            <img src={props.icon} alt=""/>
        </span>
        {props.label}
    </a>
}

export default IconButton;