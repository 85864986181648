export interface UserAccessPageProps {
}

function UserAccessPage(props: UserAccessPageProps) {
	return (
		<div className="content-page">
			<h2>User Access Stub</h2>
		</div>
	)
}

export default UserAccessPage;
