import cardItem from "assets/imgs/icons/card-item.png";
import React from "react";
import {Link} from "react-router-dom";
import {splitCardPan, getCardArtClass, formatCurrency} from "utils";

export interface CardViewProps {
    cardId: string;
    cardNumber: string;
    cardName: string;
    cardBalance: number;
    cardCurrency: string | null;
    cardStatus: string;
    cardType: string;
    cardDesign: number;
    selected?: boolean;
}

function CardView(props: CardViewProps) {
    
    return <>
        <div className={`${props.selected ? "is-selected" : ""}`}>
            <Link to={`/card-management/${props.cardId}`} 
                  className={`card d-block ${getCardArtClass(props.cardDesign)}`}>
                <img src={cardItem} className="card-item-decor" alt=""/>
                <div className={`card-status ${props.cardStatus === "Active" ? 'active' : ''} `}>{props.cardStatus}</div>
                <div className="card-balance">{formatCurrency(props.cardBalance, props.cardCurrency)}</div>
                <div className="card-number">{props.cardType}</div>
                <div className="card-number">{splitCardPan(props.cardNumber)}</div>
                <div className="card-name">{props.cardName}</div>
            </Link>
        </div>
    </>
}

export default CardView;