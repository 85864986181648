export interface BusinessProfilePageProps {
}

function BusinessProfilePage(props: BusinessProfilePageProps) {
	return (
		<div className="content-page">
			<h2>Business Profile Stub</h2>
		</div>
	)
}

export default BusinessProfilePage;
