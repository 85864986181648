import React, {PropsWithChildren, useEffect, useState} from "react";
import {Navigate, useParams} from "react-router-dom";
import API from "api/API";
import {
    CardLimit,
    CardLimits,
    CardServices,
    CardServicesRequest,
} from "api/Models";
import cardArt from "assets/imgs/icons/virtual-card.svg"
import visaLogo from "assets/imgs/visa.svg"
import iconStolen from "assets/imgs/icons/report-stolen.svg";
import iconClose from "assets/imgs/icons/close-card.svg";
import iconEdit from "assets/imgs/icons/edit.svg";
import {toast} from "react-toastify";
import {defaultToastOptions, formatCurrency, getCardArtClass, zeroPad} from "utils";
import {CircleSpinnerOverlay} from "react-spinner-overlay";
import {Col, Button} from "react-bootstrap";
import {CardData} from "api/Models";
import Label from "components/elements/Label";
import Value from "components/elements/Value";
import BasePage from "components/pages/BasePage";

interface CardViewProps {
    card: CardData
}

function CardView(props: CardViewProps) {
    return <>
        <div className="">
            <a className={`card ${getCardArtClass(props.card?.cardDesign ?? 0)} d-block`}>
                <div className="card-type"><img src={cardArt} alt=""/> VIRTUAL</div>
                <div className="card-type-logo"><img src={visaLogo} alt="logo"/></div>
                <div className="card-number-label">Card number</div>
                <div className="card-number">{props.card.processorData?.obscuredPan} </div>
                <div className="card-expire-label">Expiry date</div>
                <div className="card-expire">
                    {zeroPad(props.card.processorData?.expirationMonth ?? 0, 2)}/{props.card.processorData?.expirationYear}
                </div>
            </a>
        </div>
    </>
}

interface LimitsProps {
    label: string
    limit: CardLimit
    currency: string | null
}

function Limits(props: LimitsProps) {

    const [modalIsOpen, setModalIsOpen] = useState(false)

    return <>
        <div className="block">
            <div className="block-heading">Spending limits: {props.label}</div>
            <div className="block-list">
                <ul>
                    <li>
                        <div className="label">
                            {formatCurrency(props.limit.cashAmount, props.currency)}
                            <span>Cash</span>
                        </div>
                        <div className="func"><a href="" onClick={() => setModalIsOpen(!modalIsOpen)}><img
                            src={iconEdit} alt=""/></a>
                        </div>
                    </li>
                    <li>
                        <div className="label">
                            {formatCurrency(props.limit.internetAmount, props.currency)}
                            <span>Internet</span>
                        </div>
                        <div className="func"><a href=""><img src={iconEdit} alt=""/></a>
                        </div>
                    </li>
                    <li>
                        <div className="label">
                            {formatCurrency(props.limit.totalAmount, props.currency)}
                            <span>Total</span>
                        </div>
                        <div className="func"><a href="" onClick={() => {
                            console.log("Imma open")
                        }}><img src={iconEdit} alt=""/></a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        {/*<Modal modalBody={CardLimitsForm} stringify={} show={modalIsOpen}/>*/}
    </>
}

function StatusTag(props: { status: string | null }) {
    switch (props.status) {
        case "Active":
            return <div className="status active">Active</div>
        case "Inactive":
            return <div className="status inactive">Inactive</div>
        case "BlockedByClient":
            return <div className="status inactive">Frozen</div>
        case "Stolen":
            return <div className="status inactive">Reported Stolen</div>
        case "Lost":
            return <div className="status inactive">Reported Lost</div>
        case "Closed":
            return <div className="status inactive">Closed</div>
        default:
            return <div className="status pending">{props.status}</div>
    }
}

interface CardControlsProps {
    cardId: string,
    cardStatus: string | null,
    setBusy: (Value: boolean) => void
    updateStatus: (status: string) => void
}

function CardControls(props: CardControlsProps) {
    const freezeCard = async () => {
        props.setBusy(true)
        const result = await API.Cards.Freeze(props.cardId)
        if (result.success) {
            props.updateStatus(result.data!.status)
        }
        props.setBusy(false)
    }

    const unfreezeCard = async () => {
        props.setBusy(true)
        const result = await API.Cards.Unfreeze(props.cardId)
        if (result.success) {
            props.updateStatus(result.data!.status)
        } else {
            toast.error("Failed to unfreeze card: " + result.error, defaultToastOptions())
            console.log("Error", result)
        }

        props.setBusy(false)
    }

    const reportStolen = async () => {
        const confirm = window.confirm("Report stolen? This will block the card. This is not reversible.");
        if (!confirm) {
            return;
        }
        props.setBusy(true)
        const result = await API.Cards.ReportStolen(props.cardId)
        if (result.success) {
            props.updateStatus(result.data!.status)
        }
        props.setBusy(false)
    }

    return <div className="block-nav">
        <div className="row">
            {/*<div className="col">*/}
            {/*    <button>*/}
            {/*        <span className="nav-icn"><img src="assets/imgs/icons/show-details.svg" alt=""/></span>*/}
            {/*        <span className="nav-link">Show details</span>*/}
            {/*    </button>*/}
            {/*</div>*/}


            {
                props.cardStatus === "Active" &&
                <div className="col">
                    <button onClick={freezeCard}>
                        <div className="nav-icn"><img src={iconStolen} alt=""/></div>
                        <div className="nav-link">Freeze Cards</div>
                    </button>
                </div>
            }
            {
                (props.cardStatus === "BlockedByCustomer" || props.cardStatus === "Closed" || props.cardStatus === "Stolen")
                && <div className="col">
                    <button onClick={unfreezeCard}>
                        <div className="nav-icn"><img src={iconStolen} alt=""/></div>
                        <div className="nav-link">Unfreeze Cards</div>
                    </button>
                </div>
            }
            <div className="col">
                <button onClick={reportStolen}>
                    <div className="nav-icn"><img src={iconStolen} alt=""/></div>
                    <div className="nav-link">Report Stolen</div>
                </button>
            </div>
            <div className="col">
                <button>
                    <div className="nav-icn red"><img src={iconClose} alt=""/></div>
                    <div className="nav-link red">Close card</div>
                </button>
            </div>
        </div>
    </div>
}

function InfoEntry(props: { label: string, value: any, editable?: boolean }) {
    return <li>
        <div className="label">
            {props.value.toString()}
            <span> {props.label}</span>
        </div>
        {

            props.editable && <div className="func">
                <a href="">
                    <img src={iconEdit} alt=""/>
                </a>
            </div>
        }
    </li>
}

function InfoPanel(props: { card: CardData }) {
    return <>
        <div className="block block-cd-details">
            <div className="block-heading">Card details</div>
            <div className="func"><a href=""><img src={iconEdit} alt=""/></a></div>
            <div className="block-list">
                <ul>
                    <InfoEntry label={"Custom Name"} value={props.card.processorData?.embossName}/>
                    <InfoEntry label={"Linked user"} value={props.card.cardholderName}/>
                    <InfoEntry label={"Created on"} value={new Date(props.card.createdOn).toDateString()}/>
                    <InfoEntry label={"Modified on"} value={new Date(props.card.modifiedOn ?? 0).toDateString()}/>
                    <InfoEntry label={"Activated on"} value={props.card.activatedPlasticAt ?
                        new Date(props.card.activatedPlasticAt).toDateString() : "Not activated"}/>
                </ul>
            </div>
        </div>
    </>
}

function ServiceEntry(props: { label: string, desc?: string, value: boolean, onChange: (value: boolean) => void }) {
    return <li>
        <Label>
            {props.label} {props.desc && <span>{props.desc}</span>}
        </Label>
        <div className="func">
            <label className="slide-button">
                <input type="checkbox" defaultChecked={props.value}
                       onChange={c => props.onChange(c.target.checked)}/>
                <span className="slide-indicator"></span>
                <span className="slide-label"></span>
            </label>
        </div>
    </li>
}

function ServicesPanel(props: { services: CardServices, onChange(services: CardServices): void }) {
    return <>
        <div className="block">
            <div className="block-heading">Transaction type limits</div>
            <div className="block-list">
                <ul>
                    <ServiceEntry label={"POS"} value={props.services.servicePOS}
                                  onChange={() => props.onChange({
                                      ...props.services,
                                      servicePOS: !props.services.servicePOS
                                  })}
                    />
                    <ServiceEntry label={"ATM"} value={props.services.serviceATM}
                                  onChange={() => props.onChange({
                                      ...props.services,
                                      serviceATM: !props.services.serviceATM
                                  })}
                    />
                    <ServiceEntry label={"Abroad"} value={props.services.serviceAbroad}
                                  onChange={() => props.onChange({
                                      ...props.services,
                                      serviceAbroad: !props.services.serviceAbroad
                                  })}
                    />
                    <ServiceEntry label={"Contactless"} value={props.services.serviceContactless}
                                  onChange={() => props.onChange({
                                      ...props.services,
                                      serviceContactless: !props.services.serviceContactless
                                  })}
                    />
                    <ServiceEntry label={"CNP"} value={props.services.serviceCNP}
                                  onChange={() => props.onChange({
                                      ...props.services,
                                      serviceCNP: !props.services.serviceCNP
                                  })}
                    />
                </ul>
            </div>
        </div>
    </>
}

function BuildCardServiceRequest(cardId: string, services: CardServices): CardServicesRequest {
    return {
        cardId: cardId,
        toggleServices: {
            posTerminal: services.servicePOS,
            cashWithdrawal: services.serviceATM,
            transactionsAbroad: services.serviceAbroad,
            internetTransactions: services.serviceCNP,
            contactlessTransactions: services.serviceContactless
        }
    }
}

export default function CardPage() {
    const routerParams = useParams()
    const id = routerParams["id"];

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [card, setCard] = useState<CardData | null>(null)
    const [cardLimits, setCardLimits] = useState<CardLimits | null>(null)
    const [cardServices, setCardServices] = useState<CardServices | null>(null)
    const [busy, setBusy] = useState(false)

    useEffect(() => {
        if (!id || card !== null) {
            return;
        }

        setLoading(true)

        async function fetchData() {
            await API.Cards.GetCardsWithBalance({"Filters.Id": id})
                .then(r => {
                    if (r.success && r.data?.data && r.data.data.length > 0) {
                        const cardData = r.data.data[0]
                        setCard(cardData)
                        setCardServices({
                            serviceATM: !!cardData.serviceATM,
                            serviceCNP: !!cardData.serviceCNP,
                            serviceAbroad: !!cardData.serviceAbroad,
                            servicePOS: !!cardData.servicePOS,
                            serviceContactless: !!cardData.serviceContactless,
                        })
                    } else {
                        setError(true)
                        toast.error("Failed to load card data", defaultToastOptions())
                    }

                })

            await API.Cards.GetCardUsageLimits(id!)
                .then(r => {
                    if (r.data) {
                        setCardLimits(r.data)
                    } else {
                        setError(true)
                        toast.error("Failed to load card data", defaultToastOptions())
                    }
                })
        }

        fetchData().then(() => setLoading(false))
    }, [])

    if (error || !routerParams["id"]) {
        return <Navigate to={"/missing"}/>
    }

    if (card === null) {

        return <BasePage className="cd-management">
            <CircleSpinnerOverlay/>
        </BasePage>
    }

    const submitCardLimits = () => {
        if (cardLimits && id) {
            API.Cards.SetCardUsageLimits(id, {...cardLimits, daily: {...cardLimits!.daily, cashAmount: 2000}})
                .then(r => {
                    console.log("Set card usage", r)
                })
        }
    }

    return <>
        {
            busy && <CircleSpinnerOverlay/>
        }
        <BasePage>
            <Col lg={4}>
                <CardBlock>
                    <CardView card={card}/>
                    <StatusTag status={card.status}/>
                    <CardControls
                        cardId={card.id ?? ""}
                        cardStatus={card.status}
                        setBusy={setBusy}
                        updateStatus={(status) => {
                            setCard({...card, status: status})
                        }}
                    />
                </CardBlock>
                <InfoPanel card={card}/>
            </Col>
            <Col lg={4}>
                <CardBalanceBlock>
                    <Label>Available funds</Label>
                    <Value>{formatCurrency(card.balance ?? 0, card.currency)} </Value>
                    <div className="to-time"> November 23, 2023 | 02:20 PM</div>
                </CardBalanceBlock>
                {
                    cardServices &&
                    <ServicesPanel services={cardServices} onChange={async (services) => {
                        setCardServices(services)
                        await API.Cards.SetCardServicesTest(id as string, services)
                    }}/>
                }
            </Col>
            {
                cardLimits && <Col lg={4}>
                    <Limits label={"Daily"} limit={cardLimits.daily} currency={card.currency}/>
                    <Limits label={"Weekly"} limit={cardLimits.weekly} currency={card.currency}/>
                    <Limits label={"Monthly"} limit={cardLimits.monthly} currency={card.currency}/>
                    <Button onClick={submitCardLimits}> Send New Limits</Button>
                </Col>
            }
        </BasePage>
    </>
}

function CardBlock(props: PropsWithChildren) {
    return <div className="block block-card">
        {props.children}
    </div>
}

function CardBalanceBlock(props: PropsWithChildren) {
    return <div className="block block-card-balance">
        {props.children}
    </div>
}