import {Link } from "react-router-dom";
import logo from "./../../assets/imgs/logo.svg"
import {BriefcaseIcon, CardIcon, ChartIcon, HomeIcon, HourglassIcon, PieChartIcon, UserIcon} from "../icons/Icons";
import React from "react";
import {
    accountStatementsRoute, businessProfileRoute,
    cardManagementRoute,
    dashboardRoute, spendingAnalysisRoute,
    transactionsRoute,
    userManagementRoute
} from "../../Routes";

interface MenuItemData {
    to: string, 
    name: string,
    icon: React.JSX.Element
    notificationCount?: number
}

export interface SidebarProps {
    accountStatementNotificationCount?: number;
}

function Sidebar(props: SidebarProps) {
    const routes: MenuItemData[] = [
        {
            to: dashboardRoute,
            name: "Dashboard",
            icon: <HomeIcon/>
        },
        {
            to: transactionsRoute,
            name: "Transaction History",
            icon: <HourglassIcon/>
        },
        {
            to: cardManagementRoute,
            name: "Card Management",
            icon: <CardIcon/>
        },
        {
            to: userManagementRoute,
            name: "User Management",
            icon: <UserIcon/>
        },
        {
            to: accountStatementsRoute,
            name: "Account Statements",
            icon: <PieChartIcon/>,
            notificationCount: props.accountStatementNotificationCount,
        },
        {
            to: spendingAnalysisRoute,
            name: "Spending Analysis",
            icon: <ChartIcon/>
        },
        {
            to: businessProfileRoute,
            name: "Business Profile/Account",
            icon: <BriefcaseIcon/>
        },
    ]
    

    return (
        <aside className="general-nav">
            <Link to="/" className="logo">
                <img src={logo} alt=""/>
            </Link>
            <nav>
                <ul>
                    {routes.map((r, index) =>
                        <li key={index} className={window.location.pathname.startsWith(r.to) ? "active" : ""}>
                            <Link to={r.to}>
                            <span className="menu-icn">
                                {r.icon}
                            </span>
                                {r.name}
                                {
                                    !r.notificationCount && <span className="num-notifications">{r.notificationCount}</span>
                                }
                            </Link>
                        </li>
                    )}
                </ul>
            </nav>
        </aside>
    )
}

export default Sidebar;