import cardItem from "assets/imgs/icons/card-item.png";
import visaLogo from "assets/imgs/visa.svg";
import actionDots from "assets/imgs/icons/action-dots.svg";
import iconFreeze from "assets/imgs/icons/freeze.svg";
import iconStolen from "assets/imgs/icons/report-stolen.svg";
import iconActivate from "assets/imgs/icons/activate-v2.svg";
import iconClose from "assets/imgs/icons/close-card.svg";
import {formatCurrency, getCardArtClass, splitCardPan} from "utils";
import {Link} from "react-router-dom";
import { Col } from "react-bootstrap";

interface CardCardProps {
    id: string;
    type: string;
    number: string;
    limit: number;
    balance: number;
    currency: string;
    design: number;
    cardId: string;
    customName: string;
    linkedUser?: string;
    status: string;
}


interface DropDownMenuProps {
    show: boolean;
}

function DropDownMenu(props: DropDownMenuProps) {
    return (
        <div className="dropdown card-action-dropdown">
            <button className="card-action"
                    type="button"
                //id="dropdownMenuButton1" 
                    data-bs-toggle="dropdown"
                    aria-expanded="false">
                <img src={actionDots} alt=""/>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                    <Link className="dropdown-item" to="/card-management/freeze">
                        <img src={iconFreeze} alt="Freeze Card icon"/>
                        Freeze Card
                    </Link>
                </li>
                <li>
                    <Link className="dropdown-item" to="/card-management/stolen">
                        <img src={iconStolen} alt="Report Card icon"/>
                        Report stolen/lost
                    </Link>
                </li>
                <li>
                    <Link className="dropdown-item" to="/card-management/activate">
                        <img src={iconActivate} alt="Activate Card icon"/>
                        Activate
                    </Link>
                </li>
                <li>
                    <Link className="dropdown-item" to="/card-management/close">
                        <img src={iconClose} alt="Close Card icon"/>
                        Close Card
                    </Link>
                </li>
            </ul>
        </div>
    )
}

function CardCard(props: CardCardProps) {
    return (
        <Col xl={3} lg={4} md={6} sm={12} className="cd-management">
            <div className="cd-holder">
                <a href={`card-management/${props.id}`} className={`cd card ${getCardArtClass(props.design)} d-block`}>
                    <img src={cardItem} className="card-item-decor" alt=""/>
                    <div className="card-type"><img src={visaLogo} alt=""/></div>
                    <div className="card-number">{splitCardPan(props.number)}</div>
                    <div className="card-balance">{formatCurrency(props.balance, props.currency)}</div>
                </a>
                <ul className="cd-info cd-rows">
                    <li>
                        <div className="label">Custom name</div>
                        <div className="value card-name">{props.customName}</div>
                    </li>
                    {props.linkedUser && <li>
                        <div className="label">Linked user</div>
                        <div className="value">{props.linkedUser}</div>
                    </li>}
                    <li>
                        <div className="label">Card ID</div>
                        <div className="value">{props.cardId}</div>
                    </li>
                    <li>
                        <div className="label">Spending limits</div>
                        <div className="value">{formatCurrency(props.limit, props.currency)}</div>
                    </li>
                    <li>
                        <div className="label">Status</div>
                        <div className="value card-status active">{props.status}</div>
                    </li>
                </ul>
                <DropDownMenu show={true}/>
            </div>
        </Col>
    )
}

export default CardCard;