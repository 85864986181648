import {PropsWithChildren} from "react";

export interface ValueProps extends PropsWithChildren<any> {
    bold?: boolean
    extraClassName?: string
}

export default function Value(props: ValueProps) {
    const extraClassName = props.extraClassName ? " " + props.extraClassName : "";
    return !props.bold
        ? <div className={"value" + extraClassName}>{props.children} </div>
        : <div className={"value val-bold" + extraClassName}>{props.children} </div>
}
