import React, {MouseEvent, ReactElement, useEffect, useState} from "react";
import {animated, config, useSpring} from "@react-spring/web";

export interface ModalProps<T> extends React.HTMLAttributes<HTMLDivElement> {
    stringify?: (value: T) => string
    onCloseModal?: () => void
    onApply?: (value: T | T[] | null) => void
    options?: T[]
    show: boolean
}

export interface InternalModalProps<T> extends ModalProps<T> {
    modalBody: (props: ModalProps<T>) => ReactElement<ModalProps<T>>
}

export function Modal<T>(props: InternalModalProps<T>) {
    const [show, setShow] = useState(props.show)

    useEffect(() => {
        setShow(props.show);
    }, [props.show])

    const transformChangeValues = (show: boolean) => {
        return show ? "translate(-50%, -50%)" : "translate(-50%, -40%)"
    }

    // const OpacityChangeValues = (show: boolean) => {
    //     return show ? "1" : ".9"
    // }

    const propsModal = useSpring({
        from: {transform: transformChangeValues(!show)},//, opacity: OpacityChangeValues(!show)},
        to: {transform: transformChangeValues(show)},//, opacity: OpacityChangeValues(show)},
        config: {...config.wobbly}
    });

    const closeModal = () => {
        if (props.onCloseModal) {
            props.onCloseModal()
        }
    }

    const stopPropagation = (e: MouseEvent) => {
        e.stopPropagation();
    }

    return (
        <div {...props} prefix={"eps"} className="modal" onClick={closeModal}>
            <animated.div className="modal-selector"
                          style={propsModal}
                          onClick={stopPropagation}>
                <div className="container" onClick={event => event.stopPropagation()}>
                    <div className="row">
                        {/*<Calendar />*/}
                        <props.modalBody {...props}/>
                    </div>
                    {/*<div className="row">*/}
                    {/*    <button onClick={closeModal}>Close</button>*/}
                    {/*</div>*/}
                </div>
            </animated.div>
        </div>
    )
}

export default Modal;
