import {Link} from "react-router-dom";
import React from "react";

export interface LinkButtonProps {
    to: string;
    icon?: string;
    label?: string;
    type?: "primary" | "secondary" | "dark" | "light" | "outline-primary" | "outline-secondary" | "outline-dark" | "outline-light" | undefined;
}

function LinkButton(props: LinkButtonProps) {
    const classnames = `btn ${props.type ? "btn-" + props.type : ""} ${props.icon ? "btn-icn" : ""}`;

    return <Link to={props.to} className={classnames}>
        <span>
            <img src={props.icon} alt=""/>
        </span>
        {props.label}
    </Link>
}

export default LinkButton;