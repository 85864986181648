import React, {useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {NewCardReviewDTO} from "dtos/DTOs";
import {CircleSpinnerOverlay} from "react-spinner-overlay";
import {toast} from "react-toastify";
import {defaultToastOptions} from "utils";
import API from "api/API";
import {CardType, CreateCardRequest} from "api/Models";
import Label from "components/elements/Label";
import Value from "components/elements/Value";
import BasePage from "components/pages/BasePage";
import {Button} from "react-bootstrap";

function GetCreateCardRequest(state: NewCardReviewDTO): CreateCardRequest {
    return {
        userId: state.user.id,
        accountId: state.account.id,
        alias: state.alias,
        type: state.cardType.value as CardType,
        embossName: state.embossName,
        embossLine4: state.embossLine4,
        cardProgram: state.program,
        creditLimit: state.credit || 0
    }
}

function NewCardReviewPage(props: any) {
    const location = useLocation();
    const state = location.state as NewCardReviewDTO;
    const [busy, setBusy] = useState(false);

    const submit = async () => {
        setBusy(true);
        try {
            const result = await API.Cards.CreateCard(GetCreateCardRequest(state));
            if (result.success) {
                toast.success("Card created successfully", defaultToastOptions());
            } else {
                toast.error(`Failed to create card ${result.error}`, defaultToastOptions());
            }
        } catch (e) {
            console.error(e);
        } finally {
            setBusy(false);
        }
    }

    return (
        <>
            {
                busy && <CircleSpinnerOverlay/>
            }
            <BasePage>
                <div className="col-lg-4 offset-lg-4">
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <h4>New Card review</h4>
                        </div>
                        <div className="col">
                            <Link to={"/card-management/new-card"} className="btn btn-outline-primary" state={state}>Edit
                                details</Link>
                        </div>
                    </div>

                    <ul className="form-review">
                        <li>
                            <Label>Account</Label>
                            <Value bold>{state.account.name}</Value>
                        </li>
                        <li>
                            <Label>User</Label>
                            <Value bold>{state.user.name}</Value>
                        </li>
                        <li>
                            <Label>Card type</Label>
                            <Value bold>{state.cardType.name}</Value>
                        </li>
                        <li>
                            <Label>Alias</Label>
                            <Value bold>{state.alias}</Value>
                        </li>
                        <li>
                            <Label>Emboss Name</Label>
                            <Value bold>{state.embossName}</Value>
                        </li>
                        {

                            <li>
                                <Label>Emboss Line 4</Label>
                                <Value bold>{state.embossLine4}</Value>
                            </li>
                        }
                        <li>
                            <Label>Program</Label>
                            <Value bold>{state.program}</Value>
                        </li>
                        <li>
                            <Label>Credit</Label>
                            <Value bold>{state.credit || 0}</Value>
                        </li>
                        <li>
                            <hr/>
                        </li>
                        <li>
                            <hr/>
                        </li>
                        {
                            state.address &&
                            <li>
                                <Label>Delivery address</Label>
                                <Value>1234 Elm Street, Suite 567, New York, NY 10001</Value>
                            </li>
                        }
                    </ul>

                    {/*<div className="form-group">*/}
                    {/*    <div className="checkbox">*/}
                    {/*        <input id="terms1" type="checkbox"/>*/}
                    {/*        <label htmlFor="terms1">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do*/}
                    {/*            eiusmod*/}
                    {/*            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,*/}
                    {/*            quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo*/}
                    {/*            consequat.</label>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="form-group">*/}
                    {/*    <div className="checkbox">*/}
                    {/*        <input type="checkbox" id="terms2"/>*/}
                    {/*        <label className="label-link" htmlFor="terms2">I agree to <a*/}
                    {/*            href="">Terms &amp; Conditions</a></label>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="form-action">
                        <Button className="btn-submit" onClick={submit}>Submit</Button>
                    </div>
                </div>
            </BasePage>
        </>
    )
}

export default NewCardReviewPage;