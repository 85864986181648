import iconSearch from "assets/imgs/icons/selector-search.svg";
import iconClose from "assets/imgs/icons/close.svg";

import React, {useState} from "react";
import {Modal, ModalProps} from "components/elements/modals/Modal";

import {AccountData} from "../../../api/Models";
import {Simulate} from "react-dom/test-utils";
import change = Simulate.change;
import {hashCode} from "utils"
import input = Simulate.input;

function Option<T>(props: { value: T, label: string, cheched: boolean, onChecked: (value: boolean) => void }) {
    //return <div className="checkbox sub-checkbox">

    const id = `filter-${hashCode(props.label)}`

    return <div className="checkbox">
        <input type="checkbox" id={id} checked={props.cheched} onChange={() => props.onChecked(!props.cheched)}/>
        {/*<label htmlFor="">Dolor sit amet</label>*/}
        <label htmlFor={id}> {props.label} </label>
    </div>
}

function SelectionModalInternal<T>(props: ModalProps<T>) {
    const [selected, setSelected] = useState<T | null>(null)
    const [search, setSearch] = useState("")

    const onSubmit = (submited?: T | null) => {
        if (props.onApply) {
            if (submited !== undefined) {
                props.onApply(submited)
            } else {
                props.onApply(selected)
            }
        }

        if (props.onCloseModal) {
            props.onCloseModal()
        }
    }

    console.log("Selected", selected);
    const stringify = props.stringify
        ? props.stringify
        : (value: T) => value + ""

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col">
                    <div className="selector-search">
                        <div className="form-control-icn">
                            <img src={iconSearch} alt=""/>
                            <input type="search" name="" className="form-control" placeholder="Search"
                                   value={search}
                                   onChange={(e) => {
                                       setSearch(e.target.value)
                                   }}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-auto">
                    <button className="selector-close"><img src={iconClose} alt=""/></button>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="selector-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="selected-options">
                                    <ul>
                                        {
                                            props.options &&
                                            props.options
                                                .filter(o => !search || stringify(o).includes(search))
                                                .map(o =>
                                                    <li className={o === selected ? "active" : ""}
                                                        key={stringify(o)}
                                                        onClick={() => {
                                                            setSelected(o)
                                                            onSubmit(o)
                                                        }}>
                                                        {stringify(o)}
                                                    </li>
                                                )
                                        }
                                        {/*{*/}
                                        {/*    props.options props.options.map(s =>*/}
                                        {/*        <li className={s === selected ? "active" : ""} */}
                                        {/*            key={props.stringify(s)} */}
                                        {/*            onClick={() => setSelected(s)}>*/}
                                        {/*            {props.stringify(s)}*/}
                                        {/*        </li>*/}
                                        {/*    )*/}
                                        {/*}*/}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="selector-foot">
                        <button className="link" onClick={() => {
                            setSelected(null)
                            onSubmit(null)
                        }}>Clear
                        </button>
                        {/*<button className="btn-submit" onClick={onSubmit}>Set</button>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

function SelectionModal<T>(props: ModalProps<T>) {
    return (
        <Modal {...props} modalBody={SelectionModalInternal}/>
    )
}

// function AccountModal(props: ModalProps<AccountData[]>) {
//     return (
//         <Modal {...props} modalBody={TextModalInternal}/>
//     )
// }

export default SelectionModal;