import {setCurrentUserId} from "../../api/Session";
import React, {MouseEvent, PropsWithChildren, useState} from "react";
import {Link, useSearchParams} from "react-router-dom";
import API from "api/API";
import {LoginResponse} from "../../api/Models";
import iconFullName from "assets/imgs/icons/full-name.svg";
import iconPassword from "assets/imgs/icons/password.svg";
import logo from "assets/imgs/logo.svg";
import decor from "assets/imgs/decor.png";
import {Bounce, toast, ToastContainer} from "react-toastify";
import {Col, Button, Form, FormGroup, Row} from "react-bootstrap";

interface LoginPageProps {
    onLoggedIn: (callback?: string | null) => void
}

const toastOptions: any = {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
}

function Banner() {
    return <Col md={6}>
        <InfoSide>
            <img src={decor} className="circle-decor" alt=""/>
            <img src={logo} alt="logo"/>
            <h1>Welcome to <span>Easy Payment Services</span></h1>
            <p>Easy Payment Services Limited an EU Licensed Electronic Money Institution with full
                passport rights for the provision of payment services within the European Union.</p>
        </InfoSide>
    </Col>
}


function LoginForm(props: LoginPageProps) {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [searchParams] = useSearchParams();
    const [busy, setBusy] = useState(false)
    const [loginStep] = useState(0)

    const onSubmit = async (event: MouseEvent) => {
        event.preventDefault()

        if (busy) {
            return;
        }

        setBusy(true)

        const loginResult = await API.Users.Login({
            userName: email,
            password: password,
        })

        if (loginResult.success) {
            const data = loginResult.data as LoginResponse
            setCurrentUserId(data.userId!)
            props.onLoggedIn(searchParams.get("callback"));
            toast.success('Login successful!', toastOptions);
        } else {
            toast.error(loginResult.error, toastOptions);
        }

        setBusy(false)
    }

    return (
        <Col md={6}>
            <FormSide>
                <h2>Login</h2>
                {
                    loginStep === 0
                        ? <Form>
                            <FormGroup className={"form-group form-control-icn"}>
                                <img src={iconFullName} alt=""/>
                                <input type="email"
                                       id="email"
                                       placeholder="Email"
                                       autoFocus={true}
                                       autoCapitalize="none"
                                       autoCorrect="off"
                                       title="This field is required."
                                       name="email"
                                       autoComplete="email"
                                       value={email}
                                       onChange={(e) => setEmail(e.target.value)}
                                       className="form-control"
                                />
                            </FormGroup>
                            <FormGroup className={"form-group form-control-icn"}>
                                <img src={iconPassword} alt=""/>
                                <input type="password"
                                       id="password"
                                       name="password"
                                       placeholder="Password"
                                       autoComplete="password"
                                       className="form-control"
                                       value={password}
                                       onChange={(e) => setPassword(e.target.value)}
                                />
                            </FormGroup>
                            <Link to="/forgotten-password" className="forgot-password">Forgot your password?</Link>
                            <Button className="btn-submit" onClick={onSubmit}>LOGIN</Button>
                        </Form>
                        : <Form>
                            <Row>
                                <Col lg={{span: 6, offset: 3}}>
                                    <FormGroup className="form-group">
                                        <VerifyBlockInputs>
                                            <Row>
                                                <Col>
                                                    <input type="" className="form-control" value="0"
                                                           maxLength={1} placeholder=""/>
                                                </Col>
                                                <Col>
                                                    <input type="" className="form-control" value="1"
                                                           maxLength={1} placeholder=""/>
                                                </Col>
                                                <Col>
                                                    <input type="" className="form-control" value="2"
                                                           maxLength={1} placeholder=""/>
                                                </Col>
                                                <Col>
                                                    <input type="" className="form-control" value=""
                                                           maxLength={1} placeholder=""/>
                                                </Col>
                                            </Row>
                                        </VerifyBlockInputs>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormHint>A code has been sent to your email.</FormHint>
                            <Button className="btn-submit">LOGIN</Button>
                        </Form>
                }
            </FormSide>
        </Col>
    )
}

export function LoginPage(props: LoginPageProps) {
    return <>
        <section className="starter">
            <div className="container">
                <div className="row align-items-center g-0">
                    <Banner/>
                    <LoginForm {...props}/>
                </div>
            </div>
        </section>
        <ToastContainer/>
    </>
}

function InfoSide(props: PropsWithChildren<any>) {
    return <div className="info-side">
        {props.children}
    </div>
}

function FormSide(props: PropsWithChildren<any>) {
    return <div className="form-side">
        {props.children}
    </div>
}

function VerifyBlockInputs(props: PropsWithChildren<any>) {
    return <div className="verify-inputs">
        {props.children}
    </div>
}

function FormHint(props: PropsWithChildren<any>) {
    return <div className="form-hint">
        {props.children}
    </div>
}
