import API from "api/API";
import {useEffect, useState} from "react";
import {CardData} from "api/Models";
import CardCard from "components/elements/CardCard";
import BasePage from "components/pages/BasePage";
import {RiseLoader} from "react-spinners";

export interface CardManagementPageProps {
}

function CardManagementPage(props: CardManagementPageProps) {
    const [cards, setCards] = useState<CardData[] | null>(null)

    useEffect(() => {
        API.Cards.GetCards({"withBalance": true}).then(r => {
            setCards(r.data!.data)
        })
    }, []);

    return cards === null ?
        <BasePage className="cd-management">
            <RiseLoader size={20}/>
        </BasePage>
        :(
        <BasePage className="cd-management">
                {
                    cards.map(c =>
                        <CardCard
                            id={c.id ?? ""}
                            cardId={c.cardId ?? ""}
                            design={c.cardDesign ?? 0}
                            type={c.type ?? "Unknown"}
                            number={c.processorData?.obscuredPan ?? ""}
                            customName={c.processorData?.embossName ?? ""}
                            balance={c.balance ?? 0}
                            currency={c.currency ?? "USD"}
                            status={c.status ?? "Unknown"}
                            limit={c.creditLimit ?? 0}
                        />
                    )
                }
        </BasePage>
    )
}

export default CardManagementPage;