import PageHeader from "components/elements/PageHeader";
import BasePage from "components/pages/BasePage";
import React from "react";
import UserTable from "../elements/UserTable";

function UserManagementPage() {
    return (
        <>
            <PageHeader
                title={"User Management"}
            />
            <BasePage>
                <UserTable />
            </BasePage>
        </>
    )
}

export default UserManagementPage;