import {ComponentProps} from "react";

export interface IconProps extends ComponentProps<any> {
    width?: number;
    height?: number;
    fill?: "none" | string;
    color?: string;
}

function getDefaults(props: IconProps): IconProps {
    const newProps = {...props};
    newProps.width = props.width || 16;
    newProps.height = props.height || 16;
    newProps.fill = props.fill || "none";
    newProps.color = props.color || "#2C2B38";
    return newProps;
}

function getElement(path: string, props: IconProps): JSX.Element {
    return <svg width={props.width} height={props.height} viewBox={props.viewBox} fill={props.fill}
                xmlns="http://www.w3.org/2000/svg">
        <path
            d={path}
            fill={props.color}></path>
    </svg>
}

export function HomeIcon(props: IconProps): JSX.Element {
    const newProps = getDefaults(props);
    return getElement("M16 7.16712V12.2154C16 14.3026 14.3011 16.0022 12.2105 16.0022H10.1053V12.2154C10.1053 11.0571 9.16 10.1135 8 10.1135C6.84 10.1135 5.89474 11.0571 5.89474 12.2154V16.0022H3.78947C1.69895 16.0022 0 14.3047 0 12.2154V7.16712C0 6.01508 0.515789 4.94097 1.41263 4.21648L5.62316 0.829878C7 -0.277928 8.99789 -0.277928 10.3747 0.829878L14.5853 4.21437C15.4842 4.93676 15.9979 6.01298 15.9979 7.16501L16 7.16712Z", newProps)
}

export function HourglassIcon(props: IconProps): JSX.Element {
    const newProps = getDefaults(props);
    return getElement("M11.5952 5.20889L6.51711 8L1.41111 5.20889C0.53845 4.73778 0 4.44444 0 2.89778V1.33333C0 0.595556 0.622003 0 1.39254 0H11.6045C12.3751 0 12.9971 0.595556 12.9971 1.33333V2.89778C12.9971 4.44444 12.4586 4.73778 11.5952 5.20889ZM0 13.1022V14.6667C0 15.4044 0.622003 16 1.39254 16H11.6045C12.3751 16 12.9971 15.4044 12.9971 14.6667V13.1022C12.9971 11.5556 12.4586 11.2622 11.5952 10.7911L6.51711 8L1.41111 10.7911C0.53845 11.2622 0 11.5556 0 13.1022Z", newProps)
}

export function CardIcon(props: IconProps): JSX.Element {
    const newProps = getDefaults(props);
    return getElement("M12.2105 0H3.78947C1.70105 0 0 1.75067 0 3.9H16C16 1.75067 14.2989 0 12.2105 0ZM0 5.63333V9.1C0 11.2493 1.70105 13 3.78947 13H12.2105C14.2989 13 16 11.2493 16 9.1V5.63333H0ZM6.31579 9.96667H3.78947C3.32632 9.96667 2.94737 9.57667 2.94737 9.1C2.94737 8.62333 3.32632 8.23333 3.78947 8.23333H6.31579C6.77895 8.23333 7.15789 8.62333 7.15789 9.1C7.15789 9.57667 6.77895 9.96667 6.31579 9.96667Z", newProps)
}

export function UserIcon(props: IconProps): JSX.Element {
    const newProps = getDefaults(props);
    return getElement("M2.16703 4.21053C2.16703 1.88842 4.11085 0 6.50108 0C8.89131 0 10.8351 1.88842 10.8351 4.21053C10.8351 6.53263 8.89131 8.42105 6.50108 8.42105C4.11085 8.42105 2.16703 6.53263 2.16703 4.21053ZM10.0702 9.32421C9.58693 9.20421 9.05818 9.27158 8.6226 9.50737C7.30722 10.2189 5.69712 10.2189 4.38173 9.50737C3.94616 9.27158 3.4174 9.20632 2.93416 9.32421C1.20487 9.74737 0 11.2695 0 13.0253V13.8758C0 14.4126 0.147358 14.9389 0.426904 15.3958C0.654442 15.7684 1.08135 16 1.54076 16H11.4592C11.9187 16 12.3456 15.7684 12.5731 15.3958C12.8526 14.9389 13 14.4126 13 13.8758V13.0253C13 11.2695 11.7951 9.74737 10.068 9.32421H10.0702Z", newProps)
}

export function PieChartIcon(props: IconProps): JSX.Element {
    const newProps = getDefaults(props);
    return getElement("M15.7384 6.97824C15.533 7.21395 15.2347 7.35018 14.9213 7.35234H9.72701C9.13473 7.35234 8.65486 6.87228 8.6527 6.27761V1.07906C8.6527 0.48439 9.13473 0 9.73133 0C9.77888 0 9.82644 0.00216245 9.87399 0.00864982C13.045 0.462765 15.5373 2.95608 15.9913 6.1284C16.0345 6.43546 15.9415 6.7447 15.7384 6.97824ZM14.0415 9.38721C13.8427 9.14286 13.5444 9.0023 13.2309 9.0023H8.08637C7.48977 9.0023 7.00558 8.51791 7.00558 7.92107V2.77227C7.00558 2.45871 6.86508 2.16029 6.62082 1.96135C6.38737 1.76889 6.07826 1.69104 5.77996 1.74943C1.88264 2.53223 -0.639922 6.3295 0.142569 10.2262C0.70458 13.0266 2.87048 15.2302 5.66108 15.84C6.16689 15.9459 6.68134 16 7.19796 16C8.60515 16.0022 9.98207 15.5913 11.1558 14.815C12.7554 13.7554 13.8686 12.1076 14.2534 10.2284C14.3139 9.92999 14.2339 9.62076 14.0394 9.38721H14.0415Z", newProps)
}

export function ChartIcon(props: IconProps): JSX.Element {
    const newProps = getDefaults(props);
    return getElement("M15.2 14H2C0.766 14 0 13.2553 0 12.0556V0.777778C0 0.348056 0.358 0 0.8 0C1.242 0 1.6 0.348056 1.6 0.777778V12.0556C1.6 12.4056 1.64 12.4444 2 12.4444H15.2C15.642 12.4444 16 12.7925 16 13.2222C16 13.6519 15.642 14 15.2 14ZM14.416 3.5H14.408C13.754 3.49417 13.218 4.00556 13.212 4.64139C13.212 4.74639 13.226 4.85333 13.254 4.95444L11.51 6.65C11.414 6.62472 11.314 6.61111 11.216 6.61111H11.208C11.102 6.61111 10.996 6.62861 10.896 6.65583L9.168 4.97583C9.198 4.87472 9.214 4.76972 9.216 4.66472C9.216 4.02111 8.678 3.49806 8.016 3.49806H8.008C7.354 3.49222 6.818 4.00361 6.812 4.63944C6.812 4.74444 6.826 4.85139 6.854 4.9525L5.11 6.64806C5.014 6.62278 4.914 6.60917 4.816 6.60917H4.808C4.134 6.60139 3.58 7.12833 3.572 7.78361C3.564 8.43889 4.106 8.9775 4.78 8.98528C5.454 8.99306 6.008 8.46611 6.016 7.81083C6.016 7.69417 6 7.5775 5.968 7.46472L7.698 5.78278C7.898 5.84306 8.11 5.845 8.312 5.79056L10.054 7.48417C9.888 8.10833 10.276 8.74417 10.918 8.90556C11.56 9.06694 12.214 8.68972 12.378 8.06556C12.404 7.97028 12.416 7.87306 12.416 7.77389C12.416 7.66889 12.398 7.56389 12.368 7.46278L14.098 5.78278C14.736 5.95583 15.396 5.59222 15.572 4.97194C15.75 4.35167 15.376 3.71 14.738 3.53889C14.634 3.50972 14.524 3.49611 14.416 3.49611V3.5Z", newProps)
}

export function BriefcaseIcon(props: IconProps): JSX.Element {
    const newProps = getDefaults(props);
    return getElement("M11.8884 2.45865H12.9115C13.1234 2.43406 13.3374 2.43406 13.5492 2.45865C15.0686 2.63495 16.1574 4.01095 15.9813 5.53197V7.27273H0.0186775V5.53203C-0.00588039 5.31987 -0.00588039 5.10567 0.0186775 4.89357C0.194774 3.37248 1.56917 2.28235 3.08848 2.45865H4.11173V1.43421C4.11265 0.642528 4.75344 0.000917897 5.54427 0H10.4559C11.2466 0.000917897 11.8875 0.642462 11.8884 1.43421V2.45865ZM0.0186775 8.48485V12.908C-0.157419 14.4291 0.931446 15.805 2.45076 15.9813C2.66262 16.0059 2.87656 16.0059 3.08842 15.9813H12.9116C14.4309 16.1576 15.8052 15.0675 15.9813 13.5464C16.0059 13.3343 16.0059 13.1201 15.9813 12.908V8.48485H0.0186775ZM5.54421 1.22933C5.43118 1.22933 5.33956 1.32105 5.33956 1.43421V2.45865H10.6604V1.43421C10.6604 1.32105 10.5688 1.22933 10.4558 1.22933H5.54421ZM8.01637 11.2688C8.4685 11.2688 8.83497 10.9019 8.83497 10.4493C8.8349 9.99668 8.46843 9.62972 8.01631 9.62972H8.00812C7.55927 9.63424 7.19777 9.99655 7.19777 10.4492C7.19777 10.9019 7.56424 11.2688 8.01637 11.2688Z", newProps)
}

/*
<ul>
            <li>
                <a href="business-settings.php">
                    <span class="menu-icn">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.8884 2.45865H12.9115C13.1234 2.43406 13.3374 2.43406 13.5492 2.45865C15.0686 2.63495 16.1574 4.01095 15.9813 5.53197V7.27273H0.0186775V5.53203C-0.00588039 5.31987 -0.00588039 5.10567 0.0186775 4.89357C0.194774 3.37248 1.56917 2.28235 3.08848 2.45865H4.11173V1.43421C4.11265 0.642528 4.75344 0.000917897 5.54427 0H10.4559C11.2466 0.000917897 11.8875 0.642462 11.8884 1.43421V2.45865ZM0.0186775 8.48485V12.908C-0.157419 14.4291 0.931446 15.805 2.45076 15.9813C2.66262 16.0059 2.87656 16.0059 3.08842 15.9813H12.9116C14.4309 16.1576 15.8052 15.0675 15.9813 13.5464C16.0059 13.3343 16.0059 13.1201 15.9813 12.908V8.48485H0.0186775ZM5.54421 1.22933C5.43118 1.22933 5.33956 1.32105 5.33956 1.43421V2.45865H10.6604V1.43421C10.6604 1.32105 10.5688 1.22933 10.4558 1.22933H5.54421ZM8.01637 11.2688C8.4685 11.2688 8.83497 10.9019 8.83497 10.4493C8.8349 9.99668 8.46843 9.62972 8.01631 9.62972H8.00812C7.55927 9.63424 7.19777 9.99655 7.19777 10.4492C7.19777 10.9019 7.56424 11.2688 8.01637 11.2688Z" fill="#2C2B38"></path>
                        </svg>
                    </span> Business Profile/Account
                </a>
            </li>
        </ul>
*/