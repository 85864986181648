//import React, {useState} from 'react';
//import 'bootstrap/dist/css/bootstrap.min.css';
//TODO: Move this to App.scss
import 'swiper/css';
import 'swiper/css/free-mode';
import './App.scss';
import {Layout} from "./components/Layout";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {LoginPage} from "./components/pages/LoginPage";
import {isLoggedIn} from "./api/Session";
import {useState} from "react";
import * as process from "process";

function App() {
    console.log(process.env.NODE_ENV)
    console.log("App render");

    let routes;

    console.log("isLoggedIn", isLoggedIn())
    const [loggedIn, setLoggedIn] = useState(isLoggedIn());

    const onLoggedIn = (callback? : string | null) => {
        console.log("Logged in");
        setLoggedIn(isLoggedIn());
        
        if (callback)
        {
            window.location.href = callback;
        }
    }

    // const OnLoggedOut = () => {
    // 	console.log("Logged out");
    // 	setLoggedIn(isLoggedIn());
    // }


    routes = <>
        <Route key="login" path={"/login"} element={isLoggedIn()
            ? <Navigate to={"/"}/>
            : <LoginPage onLoggedIn={onLoggedIn}/>
        }/>,
        <Route key="main" path={"/*"} element={
            isLoggedIn()
                ? <Layout/>
                : <Navigate to={`/login${window.location.search}`}/>}
        />
    </>

    return (
        <>
            <div className="App">
                <BrowserRouter>
                    <Routes>
                        {routes}
                    </Routes>
                </BrowserRouter>
            </div>
            {/*<script src="assets/js/jquery-3.6.0.min.js" type="text/javascript"></script>*/}
            {/*<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js"*/}
            {/*		integrity="sha384-kenU1KFdBIe4zVF0s0G1M5b4hcpxyD9F7jL+jjXkk+Q2h455rYXK/7HAuoJl+0I4"*/}
            {/*		crossOrigin="anonymous"></script>*/}
            {/*<script src="assets/js/flickity.pkgd.min.js" type="text/javascript"></script>*/}
            {/*<script src="assets/js/custom.js" type="text/javascript"></script>*/}
        </>

    );
}

export default App;
