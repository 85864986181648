import iconInfo from "assets/imgs/icons/transaction-info.svg";
import React, {useEffect, useState} from "react";
import {getTransactionColor, mapUserRows } from "utils";
import API from "api/API";
import {UserRow} from "api/Models";
import ExtendingButton from "components/elements/ExtendingButton";
import Table, { DateTimeCell, PhoneCell, TagCell, TextCell} from "components/elements/Table";

interface UserTableProps {
    //filters: TransactionFilter
    pageSize?: number
    noFetch?: boolean
}

function UserTable(props: UserTableProps) {
    const tableColumns = [
        "Id",
        // "Nickname",
        "Email",
        "Phone",
        "Reference Number",
        "Created On",
        "Status",
        "Registration Status",
    ]

    const [users, setUsers] = useState<UserRow[]>([])
    const [page, setPage] = useState(1)
    const [totalPageCount, setTotalPageCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const pageSize = props.pageSize ?? 10;

    useEffect(() => {
        setLoading(true)

        if (props.noFetch) {
            return;
        }

        const skip = (page - 1) * pageSize;
        const take = pageSize;

        API.Users.GetUsers({"OffsetPaging.Skip": skip, "OffsetPaging.Take": take})
            .then(result => {
                if (result.success && result.data) {
                    setTotalPageCount(Math.ceil(result.data.totalCount / result.data.pageSize))
                    setUsers(mapUserRows(result.data.data))
                }
            }).finally(() => setLoading(false))
    }, [props, page, pageSize])

    const rowRenderer = (row: UserRow) => {
        return [
            <TextCell text={row.clientNumber}/>,
            // <TextCell text={row.nickname}/>,
            <TextCell text={row.email}/>,
            <PhoneCell phone={row.phoneNumber}/>,
            <TextCell text={row.referenceNumber}/>,
            <DateTimeCell date={row.createdOn}/>,
            <TagCell text={row.status} color={getTransactionColor(row.status)}/>,
            <TagCell text={row.registrationStatus} color={getTransactionColor(row.registrationStatus)}/>,
            <ExtendingButton to="transaction-details" icon={iconInfo} label="Details" type="outline-primary"/>,
        ]
    }

    return <Table columns={tableColumns}
                  loading={loading}
                  page={page}
                  totalPageCount={totalPageCount}
                  onPageChange={setPage}
                  data={users}
                  rowRenderer={rowRenderer}/>
}

export default UserTable;