import React from "react";
import {formatCurrency, formatPhone} from "utils";
import RiseLoader from "react-spinners/RiseLoader";
import {TransactionType} from "../../api/Models";
import {getTransactionIcon} from "../../assets/assetLoader";

interface TableProps<TData> {
    columns: string[]
    data: TData[]
    rowRenderer: (data: TData) => JSX.Element[]
    page: number
    totalPageCount: number
    onPageChange?: (page: number) => void
    loading?: boolean
}

interface VendorCellProps {
    name: string
    transactionType: TransactionType
}

export function TransactionIcon(props: { transactionType: TransactionType }) {
    let icon = getTransactionIcon(props.transactionType);
    
    return <div className="transaction-icn">
        <img src={icon} alt=""/>
    </div>
}

export function VendorCell(props: VendorCellProps) {
    return (
        <>
            <TransactionIcon transactionType={props.transactionType}/>
            {props.name}
        </>
    )
}

interface DateTimeCellProps {
    date: Date
}

export function DateTimeCell(props: DateTimeCellProps) {
    return (
        <div className="transaction-date">
            {props.date.toLocaleDateString()}
            <span> {props.date.toLocaleTimeString()} </span>
        </div>
    )
}

interface TextCellProps {
    text: string
    className?: string
}

export function TextCell(props: TextCellProps) {
    return <div className={props.className}>{props.text}</div>
}


export function PhoneCell(props: { phone: string, className?: string }) {

    const value = formatPhone(props.phone)
    return <div className={props.className}>{value}</div>
}

interface CurrencyCellProps {
    amount: number
    currency: string
    className?: string
}

export function CurrencyCell(props: CurrencyCellProps) {
    const value = formatCurrency(props.amount, props.currency);
    return <div className={props.className}>{value}</div>
}

interface TagCellProps {
    text: string
    color: "declined" | "approved" | "pending"
}

export function TagCell(props: TagCellProps) {
    return <div className={`status ${props.color}`}>{props.text}</div>
}

interface PaginationProps extends React.HTMLProps<HTMLDivElement> {
    page: number,
    totalPageCount: number,
    onPageChange?: (page: number) => void
}

function Pagination(props: PaginationProps) {
    let pagesToShow: (number | string)[]

    if (props.totalPageCount < 2) {
        pagesToShow = [1];
    } else {
        pagesToShow = [1];

        const extraStart = Math.max(2, props.page - 2);
        const extraEnd = Math.min(props.totalPageCount - 1, props.page + 2);

        if (extraStart > 2) {
            pagesToShow.push('…');
        }

        for (let i = extraStart; i <= extraEnd; i++) {
            pagesToShow.push(i);
        }


        if (extraEnd < props.totalPageCount - 1) {
            pagesToShow.push('…');
        }

        pagesToShow.push(props.totalPageCount);
    }
    const onPrev = () => {
        if (props.page > 1) {
            if (props.onPageChange) {
                props.onPageChange(props.page - 1);
            }
        }
    }

    const onNext = () => {
        if (props.page < props.totalPageCount) {
            if (props.onPageChange) {
                props.onPageChange(props.page + 1);
            }
        }
    }

    const onPageClick = (page: number) => {
        if (props.onPageChange) {
            props.onPageChange(page);
        }
    }

    const numberPage = (p: number | string) => {
        return p === '…'
            ? <div key={"no-page-" + Math.random() * 2000}
                   className={"page-button" + (p === '…' ? " ellipsis" : "")}>…</div>
            : <div key={"page-" + p} className={"page-button" + (props.page === p ? " selected" : "")}
                   onClick={() => onPageClick(p as number)}>
                {p}
            </div>
    }

    return (
        <div className={props.className + " pagination"}>
            <div className={"page-button" + (props.page <= 1 ? " disabled" : "")} onClick={onPrev}> &lt; </div>
            {pagesToShow.map(p => numberPage(p))}
            <div className={"page-button" + (props.page === props.totalPageCount ? " disabled" : "")}
                 onClick={onNext}> &gt; </div>
        </div>
    )
}

function Table<TData>(props: TableProps<TData>) {
    return (
        <div className="table-responsive table-container">
            {
                props.loading && <div className="loading-overlay">
                    <div className="spinner-container sweet-loading">
                        <RiseLoader
                            color={"teal"}
                            loading={props.loading}
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                </div>
            }
            <table className={"table cm-table" + (props.loading ? " blur" : "")}>
                <thead>
                <tr>
                    {props.columns.map((column, index) => <th key={`h-${index}`}>{column}</th>)}
                </tr>
                </thead>
                <tbody>
                {
                    props.data.length
                        ? props.data.map((d, rowIndex) => <tr key={`r-${rowIndex}`}>
                                {props.rowRenderer(d)
                                    .map((cell, colIndex) =>
                                        <td key={`r-${rowIndex}-c-${colIndex}`}>{cell}</td>)}
                            </tr>
                        )
                        : <tr><span>No data</span></tr>
                }
                </tbody>
                <tfoot>
                </tfoot>
            </table>
            <div>
                {
                    props.page && props.totalPageCount
                        ? //<tr>
                        <Pagination page={props.page}
                                    className="col"
                                    totalPageCount={props.totalPageCount}
                                    onPageChange={props.onPageChange}
                        />
                        //</tr>
                        : null
                }
            </div>
        </div>
    )
}

export default Table;