import iconBriefcase from "assets/imgs/icons/business.svg";
import iconCard from "assets/imgs/icons/card.svg";
import iconExport from "assets/imgs/icons/export.svg";
import PageHeader from "components/elements/PageHeader";
import BasePage from "components/pages/BasePage";
import {AccountData, CardData } from "api/Models";
import React, {ReactElement, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {ModalProps} from "components/elements/modals/Modal";
import DateModal, {DateRange} from "components/elements/modals/DateModal";
import API, {TransactionFilter} from "api/API";
import SelectionModal from "../elements/modals/SelectionModal";
import Select, {ActionMeta, SingleValue} from "react-select";
import {EnumDTO, TransactionStatus} from "../../dtos/DTOs";
import TransactionTable from "../elements/TransactionTable";
import {Col, Row} from "react-bootstrap";
import ExtendingButton from "../elements/ExtendingButton";

interface FilterProps<T> {
    options?: T[]
    value: T | null
    icon: string
    stringify?: (value: T) => string
    onChange?: (Value: T | null) => void
    modal: (props: ModalProps<T>) => ReactElement<ModalProps<T>>
    placeholder?: string
}

//TODO: Maybe remove?
// interface FilterMultiProps<T> {
//     options?: T[]
//     value: T[] | null;
//     icon: string
//     stringify?: (value: T) => string
//     stringifyMulti?: (value: T[]) => string
//     onChange?: (Value: T[]) => void
//     modal: (props: ModalProps<T>) => ReactElement<ModalProps<T>>
// }


function Filter<T>(props: FilterProps<T>) {
    let valueString = "";
    const [modalIsOpen, setModalIsOpen] = useState(false)

    if (props.value) {
        if (props.stringify) {
            valueString = props.stringify(props.value)
        } else {
            valueString = JSON.stringify(props.value)
        }
    }

    const ModalFunc = props.modal;

    const onClick = () => {
        if (!modalIsOpen) {
            setModalIsOpen(true);
        }
    }

    return <div className={`pick ${!!props.value}`} onClick={onClick}>
        <img src={props.icon} alt=""/>
        <span className="val">{
            props.value
                ? valueString
                : props.placeholder
        }</span>
        {modalIsOpen && <ModalFunc
            show={modalIsOpen}
            onCloseModal={() => {
                setModalIsOpen(false)
            }}
            onApply={(v: T | T[] | null) => {
                if (props.onChange) {
                    props.onChange(v as T | null)
                }
            }}
            stringify={props.stringify
                ? props.stringify
                : (v: T) => v + ""
            }
            options={props.options ?? []}
        />}
    </div>
}

//TODO: Maybe remove?
// function FilterMulti<T>(props: FilterMultiProps<T>) {
//     let valueString = "";
//
//     const [modalIsOpen, setModalIsOpen] = useState(false)
//
//     if (props.value) {
//         if (props.stringifyMulti) {
//             valueString = props.stringifyMulti(props.value)
//         } else {
//             valueString = JSON.stringify(props.value)
//         }
//     }
//
//     const ModalFunc = props.modal;
//
//     const onClick = () => {
//         if (!modalIsOpen) {
//             setModalIsOpen(true);
//         }
//     }
//
//     return <div className={`pick ${!!props.value}`} onClick={onClick}>
//         <img src={props.icon} alt=""/>
//         <span className="val">{valueString}</span>
//         {modalIsOpen && <ModalFunc
//             show={modalIsOpen}
//             onCloseModal={() => {
//                 setModalIsOpen(false)
//             }}
//             onApply={(v: T | T[] | null) => {
//                 if (props.onChange) {
//                     if (v === null) {
//                         props.onChange([])
//                     } else {
//                         props.onChange(v as T[])
//                     }
//
//                 }
//             }}
//             stringify={props.stringify
//                 ? props.stringify
//                 : (v: T) => v + ""
//             }
//             options={props.options ?? []}
//         />}
//     </div>
// }

interface TransactionStatusSelectProps {
    placeholder?: string
    value?: EnumDTO | null
    readonly options: readonly (EnumDTO & {})[]
    onChange?: (value: EnumDTO | null) => void
    defaultValue?: EnumDTO
    styles?: any
    className?: string
}


function TransactionStatusSelect(props: TransactionStatusSelectProps) {
    const onChange = (option: SingleValue<EnumDTO>, actionMeta: ActionMeta<EnumDTO>) => {
        if (props.onChange) {
            props.onChange(option);
        }
    }

    let value: SingleValue<EnumDTO> = props.value as EnumDTO;
    if (value === undefined) {
        value = null;
    }

    return (
        <Select
            options={props.options}
            onChange={onChange}
            placeholder={props.placeholder}
            getOptionLabel={(option: EnumDTO) => option.displayName}
            getOptionValue={(option: EnumDTO) => option.displayName}
            isClearable={true}
            styles={props.styles}
            className={props.className}
            classNamePrefix="pick"
        />
    )
}


interface TransactionHistoryFilter {
    account: AccountData | null
    date: DateRange | null
    transactionType: string | null
    cards: CardData | null
}


function HeaderBody(props: { onFilterChange: (t: TransactionHistoryFilter) => void }) {
    const [filter, setFilter] = useState<TransactionHistoryFilter>({
        account: null, date: null, transactionType: null, cards: null
    })

    const dateRangeStringify = (v: DateRange | null) => {
        if (!v || !v.start || !v.end) {
            return "All time";
        } else if (v.start === v.end) {
            return v.start.toLocaleDateString()
        } else {
            return `${v.start.toLocaleDateString()} - ${v.end.toLocaleDateString()}`
        }
    }

    const [accounts, setAccounts] = useState<AccountData[] | null>(null)
    const [cards, setCards] = useState<CardData[] | null>(null)

    useEffect(() => {
        API.Accounts.GetActiveAccounts()
            .then(result => {
                if (result.success && result.data) {
                    setAccounts(result.data.data)
                }
            })

        API.Cards.GetCards()
            .then(result => {
                if (result.success && result.data) {
                    setCards(result.data.data)
                }
            })

    }, []);

    return (
        <Col>
            <div className="filter-bar">
                <Row className="align-items-center">
                    <Col xs={12}>
                        <div className="filter-lab">Filter by</div>
                    </Col>
                    <Col xs="auto">
                        <Filter value={filter.account}
                                options={accounts ?? []}
                                icon={iconBriefcase}
                                modal={SelectionModal}
                                stringify={a => a.name}
                                placeholder="Any Account"
                                onChange={(v) => {
                                    const newFilter = {...filter, account: v};
                                    setFilter(newFilter);
                                    props.onFilterChange(newFilter);
                                }}
                        />
                    </Col>
                    <Col xs="auto">
                        <Filter value={filter.date}
                                icon={iconBriefcase}
                                modal={DateModal}
                                stringify={dateRangeStringify}
                                placeholder="Any Date"
                                onChange={(v) => {
                                    const newFilter = {...filter, date: v};
                                    setFilter(newFilter);
                                    props.onFilterChange(newFilter);
                                }}
                        />
                    </Col>
                    <Col xs="auto">
                        {/*<Filter value={[]} icon={iconCard}/>*/}
                        <Filter value={filter.cards}
                                options={cards ?? []}
                                icon={iconCard}
                                modal={SelectionModal}
                                placeholder="Any Card"
                                stringify={c => `${c.cardholderName} ${c.processorData?.obscuredPan}`}
                                onChange={(v) => {
                                    const newFilter = {...filter, cards: v};
                                    setFilter(newFilter);
                                    props.onFilterChange(newFilter);
                                }}
                        />
                    </Col>
                    <Col xs="auto">
                        <input type="text" className="form-control" placeholder="Amount"/>
                    </Col>
                    <Col xs="auto">
                        <TransactionStatusSelect
                            placeholder={"Transaction Status"}
                            options={TransactionStatus}
                            className="pick2 pick-arrow"
                            onChange={(v) => {
                                const newFilter = {
                                    ...filter,
                                    transactionType: v?.displayName ?? null
                                };
                                setFilter(newFilter);
                                props.onFilterChange(newFilter);
                            }}
                        />
                    </Col>
                    <Col xs="auto">
                        <ExtendingButton to={"extendo"} 
                                         icon={iconExport}
                                         label="Export"
                                         type="outline-primary"/>
                        {/*<Link to="" className="icn-btn btn-export btn-outline-primary">*/}
                            {/*<img src={iconExport} alt=""/>*/}
                            {/*<span>Export</span>*/}
                        {/*</Link>*/}
                    </Col>
                </Row>
            </div>
        </Col>
    )
}

function TransactionHistoryPage() {
    const [filters, setFilters] = useState<TransactionFilter>({
        OrderBy: "dateCreatedUtc",
    })

    //const [account, setAccount] = useState()

    const onFilterChange = (t: TransactionHistoryFilter) => {
        const startDate = t.date?.start?.toISOString().substring(0, 10) ?? undefined;
        const endDate = t.date?.end?.toISOString().substring(0, 10) ?? undefined;

        let selectedAccount = !t.account
            ? undefined
            : t.account.id

        let cardId = !t.cards
            ? undefined
            : t.cards.id ?? undefined

        setFilters({
            ...filters,
            "CreatedOn.StartDate": startDate,
            "CreatedOn.EndDate": endDate,
            AccountId: selectedAccount,
            CardId: cardId,
            TransactionStatus: t.transactionType ?? undefined
        })
    }

    return (
        <>
            <PageHeader
                title={"Transaction History"}
                body={<HeaderBody onFilterChange={onFilterChange}/>}
            />
            <BasePage>
                <TransactionTable filters={filters}/>
            </BasePage>
        </>
    )
}

export default TransactionHistoryPage;