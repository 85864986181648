import React, {useState} from "react";
import {DateRangePicker, Range} from "react-date-range";
import {Modal, ModalProps} from "components/elements/modals/Modal";

export interface DateRange {
    start: Date | undefined
    end: Date | undefined
}

function DateModalInternal(props: ModalProps<DateRange>) {
    const [range, setRange] = useState<Range>(
        {startDate: new Date(Date.now()), endDate: new Date(Date.now())}
    )
    
    const onClearAll = () => {
        setRange({startDate: undefined, endDate: undefined});
        
        if (props.onApply) {
            props.onApply({start: undefined, end: undefined})
        }
        
        if (props.onCloseModal) {
            props.onCloseModal()
        }
    }

    const onSubmit = () => {
        if (props.onApply) {
            props.onApply({start: range.startDate, end: range.endDate})
        }
        
        if (props.onCloseModal) {
            props.onCloseModal()
        }
    }

    return (
        <div className="row">
            <div className="col-12">
                <DateRangePicker
                    ranges={[range]}
                    onChange={(c) => {
                        console.log("c", c)
                        setRange(c.range1)
                    }}
                />
            </div>
            <div className="col-12">
                <div className="selector-foot">
                    <button className="link" onClick={onClearAll}>Clear all</button>
                    <button className="btn-submit" onClick={onSubmit}>Set</button>
                </div>
            </div>
        </div>
    )
}

function DateModal(props: ModalProps<DateRange>) {
    return (
        // eslint-disable-next-line react/jsx-no-undef
        <Modal {...props} modalBody={DateModalInternal}/>
    )
}

export default DateModal;
