//import Api from "api/Api";
//import notificationIcon from "assets/imgs/icons/notification.svg"
import {clearSession, getCurrentUserId} from "../../api/Session";
import {Container, Row} from "react-bootstrap";
import {PropsWithChildren} from "react";
import companyLogo from "assets/imgs/company-logo.svg";

function TopBar() {
    const onClickLogout = (e: React.MouseEvent) => {
        //e.preventDefault();
        console.log("Logout clicked");
        clearSession()
    }

    return (
        <header className="topbar">
            <Container fluid={true}>
                <Row className="align-items-center row-cols-auto justify-content-end">
                    <a href="/" className="col" onClick={onClickLogout}>
                        <div className="user-bar">
                            <img src={companyLogo} alt="Company Logo"/>
                            <Letter>{getCurrentUserId().charAt(0)}</Letter>
                        </div>
                    </a>
                </Row>
            </Container>
        </header>
    )
}

function Letter(props: PropsWithChildren) {
    return <div className="letter">{props.children}</div>
}

export default TopBar;