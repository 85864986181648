import {TransactionData, TransactionRow, UserData, UserRow} from "./api/Models";
import {PhoneCodes} from "./dtos/DTOs";
import {Flip} from "react-toastify";

export function formatCurrency(value: number, currency: string | null) {
    if (!currency) {
        return value.toString();
    }

    if (Number.isNaN(value)) {
        return "NaN";
    }

    try {
        return Intl
            .NumberFormat(navigator.language,
                {style: 'currency', currency: currency})
            .format(value);
    } catch {
        return value.toString();
    }
}

export function formatPhone(phone: string): string {
    if (phone.length < 2) {
        return phone;
    }

    const potentialCode = phone.substring(0, 2);

    if (potentialCode in PhoneCodes) {
        return `+${PhoneCodes[potentialCode]} ${phone.substring(2)}`;
    }

    return phone;
}

export function splitCardPan(pan: string): string {
    if (pan.length !== 16) {
        return pan;
    }

    return pan.match(/.{1,4}/g)?.join(" ") ?? pan;
}

export function getCardArtClass(cardDesign: number) {
    const design = cardDesign % 4 + 1;
    return 'cd-01'// + Math.floor(design);
}

export function getTransactionColor(status: string) {
    if (!status) {
        return "pending";
    }

    switch (status.toLowerCase()) {
        case "approved":
            return "approved";
        case "declined":
            return "declined";
        default:
            return "pending";
    }
}

export function mapTransactionRows(array: TransactionData[]): TransactionRow[] {
    return array.map((t) => ({
            id: t.id,
            merchant: t.description,
            transactionType: t.transactionType,
            date: new Date(t.dateCreatedUtc),
            card: t.obscuredPan,
            amount: t.amountOriginalTransaction.toString(),
            currency: t.currencyOriginalTransaction,// "USD",
            status: t.transactionStatus,
        })
    )
}

export function mapUserRows(array: UserData[]): UserRow[] {
    return array.map((c) => ({
            clientNumber: c.clientNumber,
            createdOn: new Date(c.createdOn),
            status: c.status,
            registrationStatus: c.registrationStatus,
            nickname: c.nickname,
            email: c.email,
            phoneNumber: c.phoneNumber,
            referenceNumber: c.referenceNumber,
        })
    )
}

export function zeroPad(num: number, places: number) {
    return String(num).padStart(places, '0');
}

export function defaultToastOptions(): any {
    return {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Flip,
    }
}

/**
 * Returns a hash code from a string
 * @param  {String} str The string to hash.
 * @return {Number}    A 32bit integer
 * @see http://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
 */
export function hashCode(str: string) {
    let hash = 0;
    const len = str.length;

    for (let i = 0; i < len; i++) {
        let chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0;
    }
    return hash;
}