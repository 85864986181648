import BasePage from "components/pages/BasePage";

export interface AccountStatementsPageProps {
}

function AccountStatementsPage(props: AccountStatementsPageProps) {
	return (
		<BasePage>
			<h2>Account Statement Stub</h2>
		</BasePage>
	)
}

export default AccountStatementsPage;
