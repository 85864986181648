export enum LoginType {
    email = 'email',
    phone = 'phone',
}

export enum CardType {
    Virtual = "Virtual",
    Physical = "Physical",
    VirtualWithPlastic = "VirtualWithPlastic"
}

export enum CardStatus {
    AwaitingApproval = "AwaitingApproval",
    Requested = "Requested",
    InActive = "InActive",
    Active = "Active",
    BlockedByCustomer = "BlockedByCustomer",
    BlockedByCreditor = "BlockedByCreditor",
    BlockedByIssuer = "BlockedByIssuer",
    BlockedByCardProcessor = "BlockedByCardProcessor",
    Lost = "Lost",
    Stolen = "Stolen",
    Damaged = "Damaged",
    Closed = "Closed",
    Renewed = "Renewed",
    Expired = "Expired",
    Misappropriation = "Misappropriation",
    UnauthorizedUse = "UnauthorizedUse",
    WSRequestFailed = "WSRequestFailed",
    AwaitingWSRequest = "AwaitingWSRequest"
}

export enum TransactionType {
    Pos = "Pos",
    Ecommerce = "Ecommerce",
    Atm = "Atm",
    PosCash = "PosCash",
    Fee = "Fee",
    AnnualFee = "AnnualFee",
    Manual = "Manual",
    Preauthorization = "Preauthorization",
    PinChange = "PinChange",
    Balance = "Balance",
    FastCash = "FastCash",
    PinSelection = "PinSelection",
    PaperPin = "PaperPin",
    ecomm = "ecomm",
    annual_fee = "annual_fee",
    pin_change = "pin_change",
    fast_cash = "fast_cash",
    emoney_redeem = "emoney_redeem",
    FXChange = "FXChange",
    PersonalTransfer = "PersonalTransfer",
    CreditTransfer = "CreditTransfer",
    P2PTransfer = "P2PTransfer"
}

export interface AccessTokenResponse {
    scope: string | null;
    access_token: string | null;
    expiresIn: number | null;// <int64>
    refresh_expires_in: number | null;// <int64>
    refresh_token: string | null;
    token_type: string | null
    idToken: string | null;
    //Why does it have to be like this?
    "not-before-policy": number | null;// <int32>
    session_State: string | null;
    otherClaims: any;
    error: string | null;
    errorDescription: string | null;
    errorUri: string | null;
}

export interface LoginData {
    userName: string;
    password: string;
}

export interface LoginRequest {
    loginType: LoginType;
    clientId: string;
    clientSecret: string;
    realmName: string;
    userName: string;
    password: string;
    deviceId: string | null;
}

export interface LoginResponse {
    userId: string | null;
    tenantId: string | null;
    accessTokenResponse: AccessTokenResponse | null;
}

export interface StatusMessage {
    code: string | null;
    priority: string | null;
    title: string | null;
    description: string | null;
    field: string | null;
}

export interface RoleRequest {
    id: string | null;
    name: string | null;
}

export interface PagedData<T> {
    totalPages: number
    pageSize: number
    totalCount: number
    data: T[]
}

export interface TransactionData {
    id: string,
    tenantId: string,
    userId: string,
    accountId: string,
    cardId: string,
    obscuredPan: string,
    description: string,
    transactionType: TransactionType,
    referenceNumber: string,
    triggerIndicator: string,
    operationName: string,
    transactionId: string,
    dateCreatedUtc: string, //Date, //2019-08-24T14:15:22Z,
    processingType: string,
    amountOriginalTransaction: number,
    currencyOriginalTransaction: string,
    amountTransactionBilling: number,
    currencyTransactionBilling: string,
    feeClient: number,
    feeIssuer: number,
    cardAcceptorCountry: string,
    merchantCategoryCode: string,
    merchantId: string,
    cardBalance: number,
    transactionStatus: string,
    providerReferenceNumber: number,
    cardProcessorDateUtc: Date,
    sendSms: boolean
    cardProcessorTransactionType: number,
    responseText: string
}

export interface Currency {
    name: string,
    value: number
}

export interface EnumValue {
    name: string,
    value: number
}

export interface AccountDetails {
    currency: string | null
    openingDate: string | null
    balance: number | null
    unclearedAmount: number | null
    blocked: number | null
    iban: string | null
    builtIn: boolean | null
    availableAmount: number | null
    bic: string | null
    blockAmounts: any[] | null
}

export interface AccountData {
    id: string,
    iban: string,
    createdOn: string,
    modifiedOn: string,
    closingDate: string,
    number: string,
    builtIn: true,
    status: EnumValue,
    previousStatus: EnumValue,
    details: AccountDetails | null,
    userId: string,
    tenantId: string,
    name: string,
    type: EnumValue,
    currency: Currency,
    isDefaultPaymentAccount: boolean,
    hasBlockedAmount: boolean,
    externalAuthorId: string
}

export interface Address {
    street: string,
    houseNumber: string,
    city: string,
    postcode: string,
    countryCode: string,
    alias: string
}

//==============================================================
//User
//==============================================================

export interface UserData {
    id: string,
    tenantId: string,
    realmName: string,
    referenceNumber: string,
    email: string,
    phoneNumber: string,
    clientNumber: string,
    userType: string,
    createdOn: string,
    isPEPRelated: true,
    currentRegistrationStep: string,
    registrationSteps: string,
    status: string,
    registrationStatus: string,
    registrationCancelationReason: string,
    preferredLanguage: string,
    identityVerificationsId: string,
    amlVerificationsId: string,
    idDocumentExpirationDate: string,
    nickname: string
}

//==============================================================
//Card
//==============================================================

export interface CreateCardRequest {
    userId: string,
    accountId: string
    alias: string,
    type: CardType,
    embossName: string,
    embossLine4?: string,
    cardPlasticRequest?: Address,
    cardProgram: string
    creditLimit: number
}

export interface BasicCardOperationResponse {
    cardId: string,
    status: string
}

export interface CreateCardResponse {
    id: string
    tenantId: string
    accountId: string
    issuedOn: string,
    status: string,
    alias: string,
    "processorData": object,
    requestedPlasticAt: string | null,
    convertedToPlasticAt: string | null,
    activatedPlasticAt: string | null,
    type: CardType
}

export interface ApiResponse<T> {
    data: T | ApiError[]
    status: ApiStatus
}

export interface CardProcessorDataResponse {
    obscuredPan: string | null
    embossName: string | null
    expirationMonth: number
    expirationYear: number
    expiryDate: string | null
}

export interface CardData {
    id: string | null
    tenantId: string | null
    accountId: string | null
    status: string | null
    alias: string | null
    processorData: CardProcessorDataResponse | null
    requestedPlasticAt: string | null
    convertedToPlasticAt: string | null
    activatedPlasticAt: string | null
    type: CardType
    cardProgram: string | null
    cardDesign: number | null
    cardTariff: number | null
    creditLimit: number | null
    cardholderName: string | null
    previousStatus: string | null
    activationAttempts: number | null
    statusUpdateComment: number | null
    serviceATM: boolean | null
    servicePOS: boolean | null
    serviceCNP: boolean | null
    serviceAbroad: boolean | null
    serviceContactless: boolean | null
    userId: string | null
    corporationId: string | null
    createdOn: string
    modifiedOn: string | null
    email: string | null
    ssn: string | null
    phoneNumber: string | null
    balance: number | null
    currency: string | null
    cardId: string | null
    referenceNumber: string | null
    systemMessage: string | null
}

export interface CardLimits {
    daily: CardLimit,
    weekly: CardLimit,
    monthly: CardLimit
}

export interface CardServices {
    serviceATM: boolean,
    serviceCNP: boolean,
    servicePOS: boolean,
    serviceContactless: boolean,
    serviceAbroad: boolean
}

export interface CardServicesRequest {
    cardId: string,
    toggleServices: {
        posTerminal: boolean,
        cashWithdrawal: boolean,
        transactionsAbroad: boolean,
        internetTransactions: boolean,
        contactlessTransactions: boolean
    }
}

export interface CardLimit {
    cashAmount: number,
    atmUseCounter: number,
    nonCacheAmount: number,
    posUseCounter: number,
    internetAmount: number,
    internetUseCounter: number,
    motoAmount: number,
    motoUseCounter: number,
    totalAmount: number
}

export interface ApiStatus {
    correlationId: string | null;
    statusCode: number; // <int32>
    version: string | null;
    messages: StatusMessage[] | null;
}

export interface ApiError {
    correlationId: string | null;
    statusCode: number; // <int32>
    version: string | null;
    messages: StatusMessage[] | null;
}

//==============================================================
// TENANT
//==============================================================

export interface CardProgramResponse {
    id: string,
    tenantId: string,
    designId: 0,
    tariffId: 0,
    creditLimit: 0,
    program: string,
    externalTenantId: 0,
    currency: string,
    auto3DsEnabled: true,
    status: string,
    createdOn: string
    modifiedOn: string
    authorId: string
}


//==============================================================
//LOCAL
//==============================================================

export interface TransactionRow {
    id: string;
    merchant: string;
    transactionType: TransactionType;
    date: Date;
    card: string;
    amount: string;
    currency: string;
    status: string;
}

export interface UserRow {
    clientNumber: string
    createdOn: Date;
    status: string;
    registrationStatus: string;
    nickname: string;
    email: string;
    phoneNumber: string;
    referenceNumber: string;
}